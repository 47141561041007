import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MobileFeature = makeShortcode("MobileFeature");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MobileFeature screenshot="harbor" mdxType="MobileFeature">
      <h1>{`Harbor: modern feed reader`}</h1>
      <p>{`With `}<a parentName="p" {...{
          "href": "https://harbor.page"
        }}>{`Harbor`}</a>{`, I built the RSS reader I always wanted: one with a beautiful interface, smart unified timeline, and no reliance on any third-party services for fetching or syncing feeds. I wanted a feed reader that I could browse casually like I do my Twitter timeline, with the ability to aggregate from hundreds of sources without feeling too overwhelming.`}</p>
      <p>{`Harbor keeps its unified timeline manageable with a unique feed rating system. When you subscribe to a feed, you can tell Harbor you’re a “casual reader” or a “completionist” for that feed. Harbor then tries to ensure that you won’t miss a post from that blog you love that only posts once a month, while still allowing you to subscribe to feeds that post dozens of times per day.`}</p>
    </MobileFeature>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      